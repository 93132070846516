import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, TablePagination } from '@mui/material';
import {searchBookings} from '../api/booking'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

import Paper from '@mui/material/Paper';

interface Booking {
  id: number;
  event_date: string;
  slot: string;
  block_size: number;
  email: string | null;
  phone: string | null;
  first_name: string | null;
  last_name: string | null;
  last_updated: number;
  // Add more fields as needed
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'event_date', headerName: 'Event Date', width: 150 },
  { field: 'slot', headerName: 'Time Slot', width: 150 },
  { field: 'block_size', headerName: 'Block Size', width: 150 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'phone', headerName: 'Phone', width: 150 },
  { field: 'first_name', headerName: 'First Name', width: 150 },
  { field: 'last_name', headerName: 'Last Name', width: 150 },
  { field: 'last_updated', headerName: 'Last Updated', width: 150 },
  // Add more fields as needed
];

const rowsPerPageOptions: number[] = [5, 10, 25, 50];

const BookingGrid: React.FC = () => {
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { isPending, error, data: bookings } =  useQuery({
    queryKey: ['bookingData'],
    queryFn: searchBookings
  });

  if (isPending) return <Box>'Loading...'</Box>;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedBookings = bookings
    ? bookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];

  return (
    <Paper>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={paginatedBookings} columns={columns} />
      </div>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={bookings ? bookings.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default BookingGrid;
