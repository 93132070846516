
import React from 'react';
import { Box, Grid } from '@mui/material'
import _ from 'lodash'

import { styled } from '@mui/system';

const TreatmentImg = styled('img')(({ theme }) => ({
  objectFit: "cover",
  width: "460px",
  height: "300px"
}));

const Subtitle = styled('h3')(({ theme }) => ({
  lineHeight: "1.41em",
  FontSize: "1.1vw",
  fontFamily: 'brandon-grot-w01-light, sans-serif',
  fontSize: '1.1vw',
  fontWeight: 400,
  color: "black"
}));

const SubtitleDesc = styled('h3')(({ theme }) => ({
  lineHeight: "1.75em",
  fontFamily: 'avenir-lt-w01_35-light1475496, sans-serif',
  fontWeight: 400,
  fontSize: "0.9vw",
  color: "black"
}));

export default function Treatments() {
  return (
    <Box sx={{
      marginTop: "120px",
      marginBottom: "30px",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw'
    }}>
      <Grid item xs={12} >
        <div>
          <Box component="h2" sx={{
            fontFamily: 'brandon-grot-w01-light, sans-serif',
            fontSize: '2.2vw',
            fontWeight: 400,
            color: "black"
          }}>
            <span>TREATMENTS WE OFFER
            </span></Box>
        </div>
        <div><Box component="h3" sx={{
          fontFamily: 'avenir-lt-w01_35-light1475496, sans-serif',
          fontSize: '1vw',
          fontWeight: 400,
          color: "black"
        }}>
          Our Specialties</Box>
        </div>
      </Grid>

      <Grid item xs={12} md={6}>
        <div>
          <Box component="img" alt="pulse" src="./static/images/95_093034_1_lit_320_200.webp" sx={{
            objectFit: "cover",
            width: "460px",
            height: "300px"
          }} />
        </div>
        <Subtitle>
          <span>ZhenLing&nbsp; ACUPUNCTURE</span>
        </Subtitle>
        <SubtitleDesc>
          <span>Balance Your Energies</span>
        </SubtitleDesc>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <TreatmentImg alt="Acupuncture" src="./static/images/Acupuncture.webp" />
        </div>
        <Subtitle>
          <span>REJUVENATION ACUPUNCTURE</span>
        </Subtitle>
        <SubtitleDesc>
          <span>Relax &amp; Rest</span>
        </SubtitleDesc>
      </Grid>

      <Grid item xs={12} md={6}>
        <div>
          <TreatmentImg alt="Traditional Chinese Medicine" src="./static/images/Traditional Chinese Medicine.webp" />
        </div>
        <Subtitle>
          <span>Herbs</span>
        </Subtitle>
        <SubtitleDesc>
          <span>Healing Your Entire Body</span>
        </SubtitleDesc>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <TreatmentImg alt="20160811100554201_small.jpg" src="./static/images/20160811100554201_small.webp" />
        </div>
        <Subtitle>
          <span>I Ching/Taichi ACUPUNCTURE</span>
        </Subtitle>
        <SubtitleDesc>
          <span>Tao and Taichi method to deep healing long symptoms.</span>
        </SubtitleDesc>
      </Grid>
    </Box>
  );
}
