
import React from 'react';
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/system';

const CeneredBox = styled('div')(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px"
}));

export default function Zhenling() {
  return (
    <Box sx={{
      zIndex: -1, display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      //alignItems: 'center',
      width: '100vw'
    }}>
      <CeneredBox>
        <Box sx={{
          marginTop: "100px",
          marginLeft: "25%",
          marginRight: "25%",
          fontFamily: "avenir-lt-w01_35-light1475496, sans-serif",
          fontSize: "20px"
        }}  >In traditional chinese medical, There are huge harmony between Nature and Human Beings.&nbsp; As Dr. Pan's close fellow in fighting for traditional chinese medical, wendy introduced zhen ling to the U.S. in 2016.&nbsp;&nbsp;The zhen ling acupunture use pulses to detect in-balance in human body and have correspondent treatment plans that resulted in a lot of mirales recently.&nbsp;&nbsp;&nbsp;
        </Box>
        <Box sx={{
          marginTop: "100px",
          marginLeft: "25%",
          marginRight: "25%",
        }} tabIndex={0} data-player="YOUTUBE" data-showcontrols="temp_show">
          <iframe data-src="https://www.youtube.com/embed/NDHUtuWGC6c"
            src="https://www.youtube.com/embed/NDHUtuWGC6c" height="277px" width="480px"
            frameBorder="0" title="External YouTube" aria-label="External YouTube" data-testid="youtube" allowFullScreen={false}></iframe>
        </Box>
        <Box sx={{
          marginTop: "100px",
          marginLeft: "25%",
          marginRight: "25%",
        }} tabIndex={1} data-player="YOUTUBE" data-showcontrols="temp_show">
          <iframe data-src="https://www.youtube.com/embed/i-G_5V5hesQ" src="https://www.youtube.com/embed/i-G_5V5hesQ" height="277px" width="480px" frameBorder="0" title="External YouTube" aria-label="External YouTube" data-testid="youtube" allowFullScreen={false}>
          </iframe>
        </Box>
        <Box sx={{
          marginTop: "100px",
          marginLeft: "25%",
          marginRight: "25%",
        }} tabIndex={2} data-player="YOUTUBE" data-showcontrols="temp_show">
          <iframe data-src="https://www.youtube.com/embed/KJgzPssSORI" src="https://www.youtube.com/embed/KJgzPssSORI" height="277px" width="480px" frameBorder="0" title="External YouTube" aria-label="External YouTube" data-testid="youtube" allowFullScreen={false}></iframe>
        </Box>
      </CeneredBox>
    </Box>
  );
}
