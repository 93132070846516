import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { green, grey, purple } from '@mui/material/colors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Layout from './Layout';

import Login from './components/login'
import Logout from './components/loggedout'
import BookingGrid from './components/bookingGrid';
import EditBooking from './components/editBooking';
import MainContent from './components/mainContent';
import PageUser from './components/admin/users';
import PageRegister from './components/register';
import PageZhenLing from './components/zhenling';
import PageBlog from './components/blog/Blog';
import PageSelfHealing from './components/selfhealing';
import PageMap from './components/map';
import PageAbout from './components/about';
import PageWorkhour from './components/admin/workhours'


const theme = createTheme({
  palette: {
    primary: {
      main: grey[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

function App() {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<MainContent />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/book" element={<BookingGrid />} />
              <Route path="/editBooking" element={<EditBooking />} />
              <Route path="/register" element={<PageRegister />} />
              <Route path="/selfheal" element={<PageSelfHealing />} />
              <Route path="/user" element={<PageUser />} />
              <Route path="/zhenling" element={<PageZhenLing />} />
              <Route path="/blog" element={<PageBlog />} />
              <Route path="/googleMap" element={<PageMap />} />
              <Route path="/about" element={<PageAbout />} />
              <Route path="/workhours" element={<PageWorkhour />} />
            </Route>
          </Routes>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
