
import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material'

const BodyImg = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}));

export default function Profiler() {
  useEffect(() => {
    /*
    let viewPortWidth = window.innerWidth && document.documentElement.clientWidth ?
      Math.min(window.innerWidth, document.documentElement.clientWidth) :
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName('body')[0].clientWidth;
    let viewPortHeight = window.innerHeight && document.documentElement.clientHeight ?
      Math.min(window.innerHeight, document.documentElement.clientHeight) :
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight;
    let node = document.querySelector(`.${classes.mainImage}`);
    let style = "width:" + viewPortWidth + "px; height:"+ viewPortHeight;
    node.setAttribute("style",style);
    node = document.querySelector(`.${classes.mainImageWrapper}`);
    node.setAttribute("style",style);
    */
  });
  return (
    <Box sx={{
      top: 0,
      overflow: "hidden",
      zIndex: -1
    }}>
      <BodyImg alt="" src="./static/images/main.webp"></BodyImg>
      <Box sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        margin: 'auto'
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <Box sx={{
            margin: "0 auto",
            maxWidth: "860px"
          }}>
            <div>
              <Box component="h1" sx={{
                fontFamily: 'brandon-grot-w01-light, sans-serif',
                fontSize: '4vw',
                fontWeight: 400,
                lineHeight: '1.25em',
                color: "white",
                textAlign: 'left',
              }}>
                <span>WEN'S ACUPUNCTURE</span></Box>
            </div>
            <Box sx={{
              fontFamily: 'avenir-lt-w01_35-light1475496, sans-serif',
              fontSize: '1.2vw',
              fontWeight: 400,
              lineHeight: '1.67em',
              color: "gray"
            }}>
              <Box component="h3" sx={{ textAlign: 'left' }}><span>Your Health is Our Top Priority</span></Box>
            </Box>
            <Box sx={{
              fontFamily: 'avenir-lt-w01_35-light1475496, sans-serif',
              fontSize: '1.1vw',
              lineHeight: '1.875em',
              color: 'silver'
            }}>
              <Box component="p" sx={{ textAlign: 'left' }}>
                <span>Wen's Acupunctre offers a natural and holistic approach to
                  medicine, that promotes healing and wellness through healthy alternatives to
                  traditional medicine. Wen's Acupunctre strongly feels it is our responsibility
                  as healthcare practitioners to encourage and to educate our patients to take
                  care of their own health. Let the healing process begin with Wen's
                  Acupunctre.</span></Box>
            </Box>
            <Box aria-disabled="false" sx={{
              width: '187px',
              height: '45px',
              borderRadius: '25px',
              border: '1px solid white'
            }}>
              <Box component="a"
                href="./book-online"
                target="_self" sx={{
                  cursor: 'pointer!important',
                  textDecoration: 'none',
                  verticalAlign: 'bottom',
                  color: 'white',
                  paddingLeft: "15px",
                  marginTop: "11px",
                  display: 'inline-block',
                  textAlign: 'left'
                }} ><span>Book an Appointment</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
