import React, { useState } from 'react';
import { CalendarCell, Position } from "./calendar_cell";
import { Box, Button } from '@mui/material';
import { Grid, Paper, Typography } from '@mui/material';
import { TimeSlot, Workhour } from '../../types/common';
import { WorkspacesOutlined } from '@mui/icons-material';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { getWeeklyWorkhours, getLastConfiguredWorkhour } from "../../api/workhour"

//type SelectionState = { start: Position | null; end: Position | null; isSelecting: boolean };

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface WeekSlot {
  day: number;
  date: Date;
}

interface SelectionState {
  start: string | null;
  end: string | null;
  isSelecting: boolean;
}

type SlotIdentifier = string; // Format: "MM/DD/YYYY-slotIndex"

// Function to generate the dates for each day of the week given a Monday date
const generateWeekSlots = (mondayDate: Date): WeekSlot[] => {
  let slots: WeekSlot[] = [];
  for (let day = 0; day < 7; day++) {
    const currentDay = new Date(mondayDate);
    currentDay.setDate(mondayDate.getDate() + day);
    slots.push({
      day,
      date: currentDay,
    });
  }
  return slots;
};

// Time slots from 8:00 AM to 7:30 PM
const timeSlots: string[] = Array.from({ length: 22 }, (_, index) => {
  const hour = Math.floor(index / 2) + 8; // Starting from 8 AM
  const minute = index % 2 === 0 ? '00' : '30';
  return `${hour > 12 ? hour - 12 : hour}:${minute} ${hour >= 12 ? 'PM' : 'AM'}`;
});
const getWorkHours = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_key, mondayDate] = queryKey;
  let workhours = await getWeeklyWorkhours(mondayDate as Date);
  //make workhours as bookings: key by date, then by slot
  let workdays: { [day: number]: { [slot: number]: Workhour } } = {};
  workhours.map(w => {
    let workday = workdays[w.day] || {};
    workday[w.slot] = w;
    if (!workdays[w.day]) {
      workdays[w.day] = workday;
    }
  })
  return workdays;
}

const getLastWorkhour = (workhour: Workhour | null | undefined) => {
  let mondayDate: Date;
  if (workhour) {
    mondayDate = new Date(workhour.day);
  } else {
    mondayDate = new Date();
  }
  // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const currentDay = mondayDate.getDay();

  // Calculate the difference between the current day and the last Monday
  // Note: If today is Sunday (0), we need to go back 6 days to get to the last Monday
  const daysSinceMonday = currentDay === 0 ? 6 : currentDay - 1;

  // Subtract the difference from the current date to get the date of the last Monday
  mondayDate.setDate(mondayDate.getDate() - daysSinceMonday)
  return mondayDate;

}

const WeeklyCalendar: React.FC = () => {

  const [selection, setSelection] = useState<SelectionState>({ start: null, end: null, isSelecting: false });

  const { data: lastWorkhour } = useQuery({
    queryKey: ['openWorkWeek'],
    queryFn: getLastConfiguredWorkhour
  });

  const mondayDate = getLastWorkhour(lastWorkhour);
  const weekSlots = generateWeekSlots(mondayDate);

  const { isPending, error, data: bookings } = useQuery({
    queryKey: ['workhours', mondayDate],
    queryFn: getWorkHours
  });

  // Convert date and time slot to a unique identifier
  const slotToIdentifier = (date: Date, slotIndex: number): SlotIdentifier => `${date.getTime()}-${slotIndex}`;

  const handleMouseDown = (identifier: SlotIdentifier) => {
    setSelection({ start: identifier, end: identifier, isSelecting: true });
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseEnter = (identifier: SlotIdentifier) => {
    console.log( "enter ", identifier);
    if (selection.isSelecting) {
      setSelection((prev) => ({ ...prev, end: identifier }));
    }
  };

  const handleMouseUp = () => {
    setSelection((prev) => ({ ...prev, isSelecting: false }));
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const isSelected = (identifier: SlotIdentifier): boolean => {
    if (bookings) {
      const [date, slot] = identifier.split('-');
      let booking_day = bookings[parseInt(date)];
      if (booking_day) {
        let booking_slot = booking_day[parseInt(slot)];
        if (booking_slot && booking_slot.booking_id) {

        }
      }
    }
    if (!selection.start || !selection.end) return false;
    const [startDay, startSlot] = selection.start.split('-');
    const [endDay, endSlot] = selection.end.split('-');
    const [currentDay, currentSlot] = identifier.split('-');
    let currentDayEpoch = parseInt(currentDay);
    return (
      currentDayEpoch >= parseInt(startDay) && currentDayEpoch <= parseInt(endDay) &&
      parseInt(currentSlot, 10) >= Math.min(parseInt(startSlot, 10), parseInt(endSlot, 10)) &&
      parseInt(currentSlot, 10) <= Math.max(parseInt(startSlot, 10), parseInt(endSlot, 10))
    );
  };

  const addWorkHours = () => {
    let workslots = [];
    weekSlots.map((workslot) => {
      let { day, date } = workslot;
      timeSlots.map((time, slotIndex) => {
        const identifier = slotToIdentifier(date, slotIndex);
        if (isSelected(identifier)) {
          workslots.push(workslot);
        }
      })
    });
  };

  const removeWorkHours = () => {
  };

  return (
    <Grid container spacing={2} sx={{
      userSelect: 'none', 
      gap: "10px",
      padding: "10px",
      BackgroundColor: "#f0f4f8"
    }}>
      <Grid item xs={1}>
        <Paper elevation={0} square sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          {timeSlots.map((time, index) => (
            <Typography key={index} variant="body2" sx={{ padding: 1, textAlign: 'right', borderBottom: '1px solid #eee' }}>
              {time}
            </Typography>
          ))}
        </Paper>
      </Grid>
      {weekSlots.map(({ day, date }) => (
        <Grid item xs key={day} >
          <Paper elevation={3} square sx={{ height: '100%'}}>
            <Typography variant="h6" sx={{ textAlign: 'center', padding: 1, backgroundColor: '#f5f5f5' }}>
              {date.toLocaleDateString(undefined, { weekday: 'short' })} {date.toLocaleDateString()}
            </Typography>
            {timeSlots.map((time, slotIndex) => {
              const identifier = slotToIdentifier(date, slotIndex);
              //set the data for each workhour.
              return (
                <Typography
                  key={slotIndex}
                  variant="body2"
                  sx={{
                    padding: 1,
                    borderBottom: '1px solid #eee',
                    minHeight: '20px',
                    backgroundColor: isSelected(identifier) ? 'rgba(25, 118, 210, 0.5)' : 'transparent',
                    cursor: 'pointer',
                    borderRadius: "8px",
                    Padding: "20px",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  }}
                  onMouseDown={() => handleMouseDown(identifier)}
                  onMouseEnter={() => handleMouseEnter(identifier)}
                >
                  {/* Optional: Display additional info here */}
                </Typography>
              );
            })}
          </Paper>
        </Grid>
      ))}
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={addWorkHours}
      >
        Add Work Hours
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={removeWorkHours}
      >
        Remove Work Hours
      </Button>

    </Grid>

  );
};

export default WeeklyCalendar;
