import React from "react";

// CUSTOM COMPONENTS
import NestedMenu from "./menu";
// MUI
import Button, { ButtonProps } from '@mui/material/Button';

// MUI ICONS
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { NestedMenuConfiguration } from "./menu/NestedMenu.Types";
import { ListItemIcon } from "@mui/material";

export interface DropdownMenuProps {
  data: NestedMenuConfiguration,
  buttonProps?: ButtonProps
}

export const DropdownMenu = React.forwardRef(function NestedDropdown(
  props: DropdownMenuProps,
  ref
) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const { data, ...buttonProps } = props;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
    data.onClick && data.onClick(event);
  };

  const handleClose = () => setAnchorEl(null);
  return ( 
    <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
      <Button
        key={data.key}
        onClick={handleClick}
        endIcon={data.subMenu && <ArrowDownIcon />}
        {...buttonProps}
      >
        {data.icon && (
          <ListItemIcon sx={{ color: "gray", minWidth:"24px" }}>
            <data.icon />
          </ListItemIcon>
        )}
        {data.caption}
      </Button>
      {data.subMenu &&
        <NestedMenu
          open={open}
          anchorEl={anchorEl}
          configuration={data.subMenu as NestedMenuConfiguration[]}
          onClose={handleClose}
        />
      }
    </div>
  );
});
