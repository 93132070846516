import axios from 'axios'
import jwtManager from '../lib/tokenManager'
import {Booking} from "../types/common"

export function createBooking(values:Booking) {
    let token = jwtManager.getNonce();
    if (token) {
        return axios.post(
            '/bookings',
            values,
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => res.data);
    } else {
        return axios.post(
            '/bookings',
            values)
            .then((res) => res.data);

    }
}

export function deleteBooking(bookingId:number) {
    let token = jwtManager.getNonce();
    if (token) {
        return axios.delete(
            `/admin/bookings/${bookingId}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then((res) => res.data);
    } else {
        return Promise.reject("createBooking Not authorized");
    }
}

export function updateBooking(values:Booking) {
    let token = jwtManager.getNonce();
    if (token) {
        return axios.patch(
            `/admin/bookings/${values.id}`,
            values,
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => res.data);
    } else {
        return axios.patch(`/admin/bookings/${values.id}`,
            values)
            .then((res) => res.data);
    }
}

export function fetchBooking(bookingId:number) {
    let token = jwtManager.getNonce();
    if (token) {
        return axios.get(
            `/bookings/${bookingId}`,
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => res.data);
    } else {
        return axios.get(`/bookings/${bookingId}`).then((res) => res.data);
    }
}

export function searchBookings(params?:any) {
    let token = jwtManager.getNonce();
    if (token) {
        return axios.post(
            '/search/bookings',
            params || {},
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => res.data);
    }
    else {
        return axios.post(
            '/search/bookings',
            params || {})
            .then((res) => res.data);
    }
}
