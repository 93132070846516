
import React from 'react';
import { styled } from '@mui/system';
import { Box, Grid } from '@mui/material'
import MainImage from './mainImage'
import Treatments from './treatments'
import Stories from './stories'
import Contacts from './contacts'

const FooterImg = styled('img')(({ theme }) => ({
  width: '100%',
  height: '510px',
  objectFit: 'cover'
}));

export default function MainContent() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container item={true} spacing={0} xs={12}>
        <Grid item={true} xs={12}>
          <MainImage></MainImage>
        </Grid>
        <Treatments></Treatments>
        <Grid item={true} xs={12}>
          <Stories></Stories>
        </Grid>
        <Grid item={true} xs={12}>
          <FooterImg alt="Birthmark"
            src="./static/images/48ee6e74ea6845d98100196efb884e25.webp"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
