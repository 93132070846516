import * as React from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Box, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Blog } from '../../types/common';


const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 20,
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8))',
    boxShadow: `0 12px 20px -10px rgba(0, 0, 0, 0.25), 
              0 4px 20px 0 rgba(0, 0, 0, 0.14), 
              0 7px 8px -5px rgba(0, 0, 0, 0.2)`,
    width: '100%',
    maxWidth: "70vw",
  },
}));

const CustomDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
  fontWeight: 'bold',
  paddingBottom: 0,
  fontSize: '1.5rem',
});

const CustomBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
    paddingRight: 0,
  },
}));


interface MainFeaturedPostProps {
  post: Blog,
  open: boolean,
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

function MainFeaturedPost(blog: MainFeaturedPostProps) {
  const [open, setOpen] = useState(blog.open);
  const handleClose = () => {
    setOpen(false);
    blog.onClose();
  };
  const post = blog.post;
  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle>{post.title}         <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton></CustomDialogTitle>
      <DialogContent>
        <CardMedia
          sx={{ height: 240 }}
          image={post.hero}
        />
        <Typography variant="body2" color="textSecondary" component="p">
          <span dangerouslySetInnerHTML={{ __html: post.body }} />
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', padding: 0, alignItems: 'center' }}>
        <Typography variant="subtitle2" color="textSecondary" component="p">
          {post.author}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" component="p">
          {post.created && new Date(post.created).toString()}
        </Typography>
      </DialogActions>
    </CustomDialog>
  );
}

export default MainFeaturedPost;
