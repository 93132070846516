import React from "react";
import NestedMenu, { NestedMenuProps } from "./NestedMenu";
import { NestedMenuContextProvider } from "./NestedMenuContext";

export default (props: NestedMenuProps) => {
  return (
    <NestedMenuContextProvider {...props}>
      <NestedMenu {...props} />
    </NestedMenuContextProvider>
  );
};
