import React from 'react';
import { Box } from '@mui/material';


export default function About() {
    return (
        <Box>

                After graduated from college,  as a doctor,
                wendy had practiced acupunture in Haidian Hospital, Beijing for 5 years.
                After that, she moved to the US in 1999. After got her NCCOM Certificate and license here in Massachusetts.
                Wendy created "Wen's Acupuncture".
                Wendy is master in acupuncture and herbs.
        </Box>
    );
}
