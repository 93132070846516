import React, { useEffect, useState, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { styled } from '@mui/material/styles';
import { Blog } from '../../types/common';

import MainFeaturedPost from './MainFeaturedPost';

// Define other styled components or use sx prop for inline styles

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: "flex",
  margin: "0 10px",
  justifyContent: "space-between"
}));

const abbr = (s: string) => {
  return (s.length > 132) ? s.slice(0, 132) + '...' : s;
}

export default function BlogComponent() {
  const pageSize = 10;
  console.log("enter blog page");
  const {
    data,
    status,
    error,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    ...result
  } = useInfiniteQuery({
    queryKey: ["blogs"],
    queryFn: async ({ pageParam }) => {
      let response = await fetch(`/blog?limit=${pageSize * 3}&page=${pageParam}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      console.log("fetching blogs ...")
      let data = await response.json();
      //organize data in 3 items per row
      let rows = [];
      let i = 0;
      let row = [];
      for (let item of data) {
        switch (i) {
          case 0:
            row = [item];
            break;
          case 1:
            row.push(item);
            break;
          case 2:
            row.push(item);
            rows.push(row);
            row = [];
            break;
        }
        if (i == 3) {
          i = 0;
        } else {
          i++;
        }
      }
      if (row && row.length > 0) {
        rows.push(row);
      }
      return rows;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage || lastPage.length === 0) {
        return undefined
      }
      return lastPageParam + 1
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined
      }
      return firstPageParam - 1
    },
    maxPages: 1000
  }
  );

  const parentRef = React.useRef(window.document.body);
  const rowVirtualizer = useVirtualizer({
    count: 1000,
    getScrollElement: () => parentRef.current as unknown as Element,
    estimateSize: () => 30,
  })

  const [viewArticle, setViewArticle] = useState<Blog | null>(null);
  let virtualItems = rowVirtualizer.getVirtualItems();

  let blogs: Blog[];
  if (data && data.pages) {
    blogs = data.pages.flat()[0];
  } else {
    blogs = []
  }

  useEffect(() => {

    const [lastItem] = virtualItems;
    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= (blogs ? blogs.length : 1) - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }

  }, [hasNextPage,
    fetchNextPage,
    blogs.length,
    isFetchingNextPage,
    virtualItems]);

  const onClick = (e: React.MouseEvent, articleId: number) => {
    e.preventDefault();
    const blog: Blog | null = blogs.find(item => item.id === articleId) || null;
    setViewArticle(blog || null);
  };

  const onShowArticles = (e: React.MouseEvent) => {
    e.preventDefault();
    setViewArticle(null);
  };

  return (
    <div className="App">
      <Typography variant="h3" color="primary">Acupuncture Blog</Typography>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 800, pb: 3 }} onClick={onShowArticles}>
          Articles
        </Typography>
        {viewArticle && <MainFeaturedPost post={viewArticle} open={!!viewArticle} onClose={()=>setViewArticle(null)}/>}
        {!viewArticle && status === 'pending' ? (
          <p>Loading...</p>
        ) : !viewArticle && status === 'error' ? (
          <span>Error: {error.message}</span>
        ) : (
          <Box sx={{
            width: '400px',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
          }}>
            {
              virtualItems.map((item) => {
                debugger;
                const blog = blogs[item.index];
                console.log("virtual item index", item.index);
                if (!blog) {
                  return;
                }
                return (
                  <Card sx={{ margin: "auto", maxWidth: "100%" }} onClick={(e) => onClick(e, blog.id)} >
                    <CardActionArea>
                      <CardMedia
                        sx={{ height: 240 }}
                        image={blog.hero}
                        title={blog.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {blog.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <span dangerouslySetInnerHTML={{ __html: abbr(blog.body) }} />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{
                      display: "flex",
                      margin: "0 10px",
                      justifyContent: "space-between"
                    }}>
                      <Box sx={{ display: "flex" }}>
                        <Box ml={2}>
                          <Typography variant="subtitle2" color="textSecondary" component="p">
                            {blog.author}
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" component="p">
                            {blog.created && new Date(blog.created).toString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <BookmarkBorderIcon />
                      </Box>
                    </CardActions>
                  </Card>
                )
              })}
          </Box>
        )}
      </Container>
    </div>
  );
}
