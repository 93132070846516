import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import useUserStore from '../stores/user';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: 20,
        padding: theme.spacing(4),
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8))',
        boxShadow: `0 12px 20px -10px rgba(0, 0, 0, 0.25), 
                0 4px 20px 0 rgba(0, 0, 0, 0.14), 
                0 7px 8px -5px rgba(0, 0, 0, 0.2)`,
        width: '100%',
        maxWidth: 400,
    },
}));

const CustomDialogTitle = styled(DialogTitle)({
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: 0,
    fontSize: '1.5rem',
});

const CustomTextField = styled(TextField)({
    margin: '12px 0',
    '& .MuiOutlinedInput-root': {
        borderRadius: 8,
    },
});

const CustomButton = styled(Button)({
    marginTop: 8,
    padding: '10px 0',
    borderRadius: 8,
});

interface LoginFormInputs {
    username: string;
    password: string;
}

function LoggedoutDialog() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };

    return (
        <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogTitle>Logged Out</CustomDialogTitle>
            <DialogContent>
                You've success logged out.
            </DialogContent>
        </CustomDialog>
    );
}

export default LoggedoutDialog;
