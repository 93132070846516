import {create} from 'zustand';
import { Blog, Pager } from '../types/common';
import jwtManager from '../lib/tokenManager';

// Define a type for the store's state
interface UserState {
  email: string | null;
  roles: string;
  login: (email: string, pwd: string) => Promise<boolean>;
  logout: () => void;
}

const url = 'search/blogs';

const useUserStore = create<UserState>((set, get) => ({
  email: null,
  roles: '',

  login: async (email: string, pwd: string) => {
    const loginUrl = 'login';
    let request = new Request(loginUrl, {
        body: JSON.stringify({username:email, password:pwd}),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
    let response = await fetch(request);
    let rlt = await response.json();
    let { error, nonce, expiry, roles }  = rlt;
    if (error) {
      return false;
    }
    jwtManager.setNonce(nonce, expiry);
    set(() => ({ email, roles }));
    return true;
  },

  logout: () => {
    set(state => ({ email:null, roles: ''}));
  }
}));

export default useUserStore;
