import React, { useState, useEffect, ChangeEvent } from 'react';
import { DataGrid, GridColDef, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
}

export default function UserComponent() {
  const [rows, setRows] = useState<User[]>([]);
  const [filteredRows, setFilteredRows] = useState<User[]>([]);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  // Fetch Users
  useEffect(() => {
    fetch('/admin/users')
      .then(response => response.json())
      .then(data => {
        setRows(data);
        setFilteredRows(data);
      });
  }, []);

  // Filter Rows on Search
  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase();
    const searchFilteredRows = rows.filter(row =>
      row.username.toLowerCase().includes(lowercasedSearchText) ||
      row.firstName.toLowerCase().includes(lowercasedSearchText) ||
      row.lastName.toLowerCase().includes(lowercasedSearchText) ||
      row.email.toLowerCase().includes(lowercasedSearchText)
    );

    setFilteredRows(searchFilteredRows);
  }, [searchText, rows]);

  // Handle Search Input Change
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(0); // Reset to first page on search
  };

  // Update Data on the Server
  const handleRowUpdate = async (newRow: User, oldRow: User) => {
    try {
      await fetch('/admin/user', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newRow)
      });
      const updatedRows = rows.map(row => (row.id === newRow.id ? newRow : row));
      setRows(updatedRows);
      return newRow;
    } catch (error) {
      console.error('Error updating user:', error);
      // Return the old row to prevent the grid from updating
      return oldRow;
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Username', width: 150, editable: true },
    { field: 'firstName', headerName: 'FirstName', width: 150, editable: true },
    { field: 'lastName', headerName: 'LastName', width: 150, editable: true },
    { field: 'email', headerName: 'Email', width: 250, editable: true },
    // Add other columns as needed
  ];

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <TextField
        value={searchText}
        onChange={handleSearchChange}
        placeholder="Search..."
        variant="outlined"
        size="small"
        fullWidth
        margin="normal"
      />
      <DataGrid
        rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={filteredRows.length}
        editMode="row"
        rowModesModel={rowModesModel}
        processRowUpdate={handleRowUpdate}
        components={{
          Toolbar: GridToolbarContainer,
        }}
      />

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        sx={{ marginTop: 2, justifyContent: "center", display: "flex" }}
      />
    </Box>
  );
}
