import React from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import DatePicker from './common/datePicker';
import { useForm, Controller } from 'react-hook-form';

interface Booking {
  event_date: Date;
  slot: string;
  block_size: number;
  email?: string | null;
  phone?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  last_updated: number;
  // Add more fields as needed
}

const BookingPage: React.FC = () => {
  const { register, control, handleSubmit, reset, formState } = useForm<Booking>();
  const { errors } = formState;

  const onSubmit = async (data: Booking) => {
    try {
      // Send the form data to create the booking
      const response = await fetch('/api/bookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Reset the form after successful submission
      reset({
        event_date: new Date(),
        slot: '10:00', // Reset to default time slot
        block_size: 30, // Reset to default block size
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        last_updated: 0, // Reset other fields as needed
      });

      // You can also handle success messages or redirects here
    } catch (error) {
      console.error('Error creating booking:', error);
      // Handle error messages or display them to the user
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Create Booking
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Appointment Date"
              control={control}
              name="event_date"
              defaultValue={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Time Slot</InputLabel>
              <Controller
                name="slot"
                control={control}
                defaultValue="10:00AM"
                render={({ field }) => (
                  <Select {...field}>
                    {Array.from({ length: 24 * 2 }).map((_, index) => {
                      const hours = Math.floor(index / 2);
                      const minutes = (index % 2) * 30;
                      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                      return (
                        <MenuItem key={formattedTime} value={formattedTime}>
                          {formattedTime}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              fullWidth
              {...register('email', { required: 'Email is required' })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone.message : ''}
              {...register('phone', { required: 'Phone Number is required' })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              error={!!errors.first_name}
              helperText={errors.first_name ? errors.first_name.message : ''}
              {...register('first_name', { required: 'First Name is required' })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              error={!!errors.last_name}
              helperText={errors.last_name ? errors.last_name.message : ''}
              {...register('last_name', { required: 'Last Name is required' })}
              name="last_name"
              fullWidth
            />
          </Grid>
          {/* Add more form fields here */}
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '16px' }}
        >
          Book Appointment
        </Button>
      </form>
      {/* Display existing bookings here */}
    </Container>
  );
};

export default BookingPage;
