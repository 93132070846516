import DashboardIcon from '@mui/icons-material/Dashboard';
import IconSubscriptions from '@mui/icons-material/Subscriptions'
import IconPlaylistPlay from '@mui/icons-material/PlaylistPlay'
import InfoIcon from '@mui/icons-material/Info';
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconSettings from '@mui/icons-material/Settings'
import SubjectIcon from '@mui/icons-material/Subject';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import BalanceIcon from '@mui/icons-material/Balance';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PeopleIcon from '@mui/icons-material/People';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import {
    AppBar,
    Toolbar,
    Typography,
    List,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu';
import useUserStore from '../stores/user';

const hasRoleCheck = (role: string) => {
    return (roles: string[]) => {
        return roles.indexOf(role) !== -1
    }
}

const anyRoleCheck = (roles: string[])=> {
    return roles && roles.length > 0;
}

const hasNoRoleCheck = (roles: string[])=> {
    return !roles || roles.length == 0;
}

export default function AppMenu() {
    const navigate = useNavigate();
    const userState = useUserStore();
    let {roles, logout} = userState;
    let appMenuItems: DropdownMenuProps[] = [
        {
            data: {
                caption: 'Home',
                key: "home",
                onClick: (event: React.MouseEvent) => {
                    navigate('/');
                },
                icon: DashboardIcon,
            }
        },
        {
            data: {
                caption: 'Bookings',
                key: 'book',
                icon: IconPlaylistPlay,
                visible:hasRoleCheck("user"),
                onClick: (event: React.MouseEvent) => {
                    navigate('/book');
                }
            }
        },

        {
            data: {
                caption: 'Blog',
                key: 'blog',
                icon: EditNoteIcon,
                onClick: (event: React.MouseEvent) => {
                    navigate('/blog');
                }
            }
        },
        {
            data: {
                key: 'more',
                caption: 'Treatments',
                icon: SubjectIcon,
                subMenu: [
                    {
                        caption: 'Self Healing Method',
                        key: 'selfheal',
                        icon: AccessibilityNewIcon,
                        onClick: (event: React.MouseEvent) => {
                            navigate('/selfheal');
                        },
                    },
                    {
                        key: "zhenling",
                        icon: BalanceIcon,
                        caption: 'ZhenLing Acupuncture',
                        onClick: (event: React.MouseEvent) => {
                            navigate('/zhenling');
                        }
                    }
                ],
            }
        },
        {
            data: {
                key: 'admin',
                caption: 'admin',
                icon: IconSettings,
                visible:hasRoleCheck("admin"),
                subMenu: [
                    {
                        caption: 'users',
                        key: 'users',
                        icon:PeopleIcon,
                        onClick: (event: React.MouseEvent) => {
                            navigate('/users');
                        },
                    },
                    {
                        caption: 'blogs',
                        icon: EditNoteIcon,
                        key: 'manage_blog',
                        onClick: (event: React.MouseEvent) => {
                            navigate('/manage_blog');
                        },
                    },
                    {
                        caption: 'stories',
                        icon: HistoryEduIcon,
                        key: 'manage_stories',
                        onClick: (event: React.MouseEvent) => {
                            navigate('/manage_stories');
                        },
                    },
                    {
                        caption: 'workhours',
                        icon: HistoryEduIcon,
                        key: 'workhours',
                        onClick: (event: React.MouseEvent) => {
                            navigate('/workhours');
                        },
                    },
                ],
            }
        },
        {
            data: {
                caption: 'About',
                key: 'about',
                onClick: (event: React.MouseEvent) => {
                    navigate('/about');
                },
                icon: InfoIcon,
            }
        },
        {
            data: {
                caption: '',
                key: '/googleMap',
                icon: IconLocationOn,
                onClick: (event: React.MouseEvent) => {
                    navigate('/googleMap');
                }
            }
        },
        {
            data: {
                key: 'login',
                caption: 'Login',
                visible:hasNoRoleCheck,
                onClick: (event: React.MouseEvent) => {
                    navigate('/login');
                }
            },
        },
        {
            data: {
                key: 'logout',
                caption: 'Logout',
                visible:anyRoleCheck,
                onClick: (event: React.MouseEvent) => {
                    logout();
                    navigate('/logout');
                }
            },
        },
    ]

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });


    console.log("user store roles", roles);
    let userRoles = (roles && roles.split(',')) || [];
    
    appMenuItems = appMenuItems.filter(item=>!item.data.visible || item.data.visible(userRoles));
    
    /*
    const { mobileView, drawerOpen } = state;
    
    let drawerMobileToggle = {
        width: "60px",
        top: "60px"
    };
    let drawerMobile = {
        width: "100%",
        top: "60px"
    }
    */
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (
            <Toolbar>
                {companyTitle}
                <List component="nav" sx={{ display: 'flex', flexDirection: 'row', ml: 'auto', p: 0 }}
                    disablePadding>
                    {appMenuItems.map((m) => (
                        <DropdownMenu data={m.data} key={m.data.key} />
                    ))}
                </List>
            </Toolbar>
        );
    };


    const companyTitle = (
        <Typography variant="h6" component="h1" sx={{
            fontFamily: "brandon-grot-w01-light, sans-serif",
            fontWeight: 600,
            fontSize: "22px",
            width: "300px",
            color: "#FFFEFE",
            textAlign: "left",
        }}
        >
            Wen's Acupuncture
        </Typography>
    );

    return (
        <header>
            <AppBar sx={{
                backgroundColor: "#505050",
                pr: "79px",
                pl: "118px",
                "@media (max-width: 900px)": {
                    pl: 0,
                },
            }}
            >
                {displayDesktop()}
            </AppBar>
        </header>
    );
}