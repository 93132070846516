import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import useUserStore from '../stores/user';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: 20,
        padding: theme.spacing(4),
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8))',
        boxShadow: `0 12px 20px -10px rgba(0, 0, 0, 0.25), 
                0 4px 20px 0 rgba(0, 0, 0, 0.14), 
                0 7px 8px -5px rgba(0, 0, 0, 0.2)`,
        width: '100%',
        maxWidth: 400,
    },
}));

const CustomDialogTitle = styled(DialogTitle)({
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: 0,
    fontSize: '1.5rem',
});

const CustomTextField = styled(TextField)({
    margin: '12px 0',
    '& .MuiOutlinedInput-root': {
        borderRadius: 8,
    },
});

const CustomButton = styled(Button)({
    marginTop: 8,
    padding: '10px 0',
    borderRadius: 8,
});

interface LoginFormInputs {
    username: string;
    password: string;
}

function LoginDialog() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const login = useUserStore((state) => state.login)
    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };

    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();

    //TODO: handle authentication error.
    const handleLogin = async (loginData: LoginFormInputs) => {
        if (await login(loginData.username, loginData.password)) {
            handleClose();
        }
    };
    return (
        <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogTitle>Login</CustomDialogTitle>
            <DialogContent>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
                    <CustomTextField
                        autoFocus
                        fullWidth
                        id="username"
                        label="Username"
                        type="text"
                        variant="outlined"
                        {...register('username', { required: 'Username is required' })}
                    />
                    <CustomTextField
                        fullWidth
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        {...register('password', { required: 'Password is required' })}
                    />
                    {errors.username && <p>{errors.username.message}</p>}
                    {errors.password && <p>{errors.password.message}</p>}

                    <CustomButton fullWidth variant="contained" color="primary" type="submit">
                        Login
                    </CustomButton>
                    <Box sx={{ display: 'block', textAlign: 'center', marginTop: 2 }}>
                        <Link to="/forgetPassword" style={{ color: "grey", textUnderlineOffset: "3px" }}>
                            Forgot password?
                        </Link>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions sx={{ flexDirection: 'column', padding: 0, alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ margin: '8px 0' }}>
                    Don't have an account?
                </Typography>
                <CustomButton fullWidth variant="outlined" onClick={() => navigate('/register')}>
                    Register
                </CustomButton>
            </DialogActions>
        </CustomDialog>
    );
}

export default LoginDialog;
