const TokenManager = () => {
    let logoutEventName = 'logout';
    let refreshEndpoint = '/refresh_token';
    let nonce:string|null = null;
    let refreshing = false;
    let tokenLife:number;
    let tokenTime:number;

    const setRefreshTokenEndpoint = (endpoint:string) => refreshEndpoint = endpoint;

    // The method makes a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will return a fresh jwt.
    const getRefreshedToken = () => {
        const request = new Request(refreshEndpoint, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json',
                                   'nonce':`${nonce}` }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    global.console.log(
                        'Failed to refresh token.'
                    );
                    return { token: null };
                }
                return response.json();
            })
            .then(({ tokenExpiry }) => {
                if (tokenExpiry) {
                    updateExpiry(tokenExpiry);
                    return true;
                }
                return false;
            }).finally(() => { refreshing = false; });
    };


    const getNonce = () => {
        if (nonce) {
            let now = Date.now()/1000;
            if (now > tokenTime && (now - tokenTime) < (tokenLife / 3) && !refreshing) {
                setTimeout(getRefreshedToken, 0);
                refreshing = true; //set refreshing here as it should happen next.
            } 
        }
        return nonce;
    }

    const updateExpiry = (delay:number) => {
        tokenLife = delay;
        tokenTime = Date.now(); //Date.now() is epoch time in Utc.
        return true;
    };

    const setNonce = (n: string, expiry: number) => {
        nonce = n;
        updateExpiry(expiry - Date.now()/1000);
    }

    const setLogoutEventName = (name:string) => logoutEventName = name;

    return {
        getNonce,
        setNonce,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        updateExpiry,
    }
};
export default TokenManager();