import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { Control, FieldValues, Controller } from "react-hook-form";
import dayjs from "dayjs";

interface CustomDatePickerProps<T> {
  name: Extract<keyof T, string>;
  label: string;
  control: any;
  error?: boolean;
  helperText?: string;
  defaultValue: any;
  onDateChange?: any;
}

const CustomDatePicker = <T,>({
  label,
  name,
  control,
  error,
  helperText,
  defaultValue,
  onDateChange,
  ...rest
}: CustomDatePickerProps<T>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={"mui-date"}>
        <Controller
          name={name}
          control={control as Control<FieldValues, any>}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label={label}
              value={value ? dayjs(value).toString() : null}
              onChange={onChange}
              slotProps={{
                actionBar: {
                  actions: ["today", "accept"],
                },
                textField: {
                  error: error,
                  helperText: helperText,
                  fullWidth: true
                },
              }}
              {...rest}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;