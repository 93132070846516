import React, { KeyboardEvent, useState } from "react";
import { NestedMenuConfiguration } from "./NestedMenu.Types";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useNestedMenuContext } from "./NestedMenuContext";
import { Menu, MenuItem, ListItemIcon, MenuProps } from "@mui/material";

export interface NestedMenuProps extends MenuProps {
    configuration: NestedMenuConfiguration[];
}

const NestedMenu: React.FunctionComponent<NestedMenuProps> = (props) => {
    const { onClose, open, ...others } = props;
    const configuration: NestedMenuConfiguration[] = props.configuration;

    const {
        openMenu,
        closeAllMenus,
        closeMenu,
        findOpenMenu
    } = useNestedMenuContext();
    const [activeItemIndex, setActiveItemIndex] = useState<number>();

    const handleSubMenuClick = (event: React.MouseEvent) => {
        const configKey = event.currentTarget.getAttribute("data-config-key");
        if (configKey) {
            openMenu(configKey, event.currentTarget);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation();

        const length = configuration.length;
        // If no item is selected, and any arrow button gets clicked, then we want to select the first item
        if (
            ["ArrowUp", "ArrowRight", "ArrowDown", "ArrowLeft"].includes(event.key) &&
            typeof activeItemIndex === "undefined"
        ) {
            setActiveItemIndex(0);
            return;
        }

        const config = configuration[activeItemIndex!];
        if (event.key === "ArrowDown") {
            if (activeItemIndex! + 1 >= length) {
                setActiveItemIndex(0);
                return;
            }
            setActiveItemIndex(activeItemIndex! + 1);
        } else if (event.key === "ArrowUp") {
            if (activeItemIndex! === 0) {
                setActiveItemIndex(length - 1);
                return;
            }
            setActiveItemIndex(activeItemIndex! - 1);
        } else if (event.key === "ArrowRight" && Array.isArray(config.subMenu)) {
            const target = document.querySelector(
                `*[data-config-key="${config.key}"]`
            );
            openMenu(config.key, target!);
        } else if (event.key === "ArrowLeft") {
            closeMenu(config.key, true);
        }
    };

    return (
        <Menu
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            {...others}
            open={open}
            onClose={closeAllMenus}
            onKeyDown={handleKeyDown}
        >
            {configuration.map((config, index) => {
                if (!Array.isArray(config.subMenu) || config.subMenu.length === 0) {
                    return (
                        <MenuItem
                            //button
                            key={config.key}
                            selected={index === activeItemIndex}
                            onClick={(e) => {
                                closeAllMenus({}, "backdropClick");
                                config.onClick && config.onClick(e);
                            }}
                        >
                            {config.icon && (
                                <ListItemIcon sx={{ color: "#97c05c" }}>
                                    <config.icon />
                                </ListItemIcon>
                            )}
                            {config.caption}
                        </MenuItem>
                    );
                }

                const openMenuConfig = findOpenMenu(config.key);
                const isOpen = typeof openMenuConfig === "object";

                return [
                    <MenuItem
                        //button
                        key={config.key}
                        selected={index === activeItemIndex}
                        data-config-key={config.key}
                        onClick={handleSubMenuClick}
                    //onMouseOver={handleSubMenuClick}
                    >
                        {config.icon && (
                            <ListItemIcon sx={{ color: "#97c05c" }}>
                                <config.icon />
                            </ListItemIcon>
                        )}
                        <span>{config.caption}</span>
                        <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                            <ArrowRightIcon />
                        </ListItemIcon>
                    </MenuItem>,
                    <NestedMenu
                        key={`${config.key}_menu`}
                        configuration={config.subMenu}
                        open={isOpen}
                        anchorEl={isOpen ? openMenuConfig!.target : null}
                        anchorOrigin={{ vertical: "center", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        //getContentAnchorEl={null}
                        onClose={closeAllMenus}
                    />
                ];
            })}
        </Menu>
    );
};

export default NestedMenu;
