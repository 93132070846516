import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import AppMenu from './components/app-menu'
import Footer from './components/footer';

export default function Layout() {
    const [open, setOpen] = React.useState(true);
    let openStyle = open ? { mr: 2 } : { mr: 2, display: 'none' };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar sx={{
                transition: theme => theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                })
            }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                            setOpen(true);
                        }}
                        edge="start"
                        sx={openStyle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Wen's Acupuncture
                    </Typography>
                    <AppMenu />
                </Toolbar>
            </AppBar>
            <main>
                <Outlet />
            </main>
            <Footer description='' />
        </Box>
    );
}
