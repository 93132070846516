import React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import { Box, Card, CardActions } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/material/styles';
import Container from '@mui/material/Container';

import { styled } from '@mui/system';

const HeroContent = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(8, 0, 6),
}));

const CardGrid = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

const cards = [
  {
    key: 1,
    title: 'Gua method to stop running nose and open the stuffy nose',
    desc: `First,  Gua the same side of the outer line of the tendon of the back neck (line 4),
    from the shoulder to the head, Gua 50 times; Second, Press those two points, 50 times of each.
    LI 11(Qu Chi) and L10(Tai Yuan).
    Those are for Spring and Summer, for Fall and Winter Gua the opposite direction.`
  },
  {
    key: 2,
    title: 'Gua method: Stuffy nose',
    desc: `First,  Gua the same side of the outer line of the tendon of the back neck (line 4), 
    from the head down to the shoulder, Gua 50 times. Second, Press those two points, 50 times of each.
     LI 11(Qu Chi) and L10(Tai Yuan).
    Those are for Spring and Summer, for Fall and Winter Gua the opposite direction.`
  },
  {
    key: 3,
    title: 'Allergy',
    desc: `Gua the same side of the neck of the relevant line as shown above:
      Line 1: the middle line of the back neck, for head.
      Line 2: for eyes
      Line 3: for throat
      Line 4: for nose  
      Watery sympton like running nose, watery eyes, phelem in the throat: from down to up, 50 times;
      Dryness sympton like ichy eyes, stuffy nose, ichy and dry throat: from up to down, 50 times.
      Headache: Gua Line 1 & Line 2, from down to up; Dizzy: these two line, from up go down.
      Repeat the treatment if the sympton is still there.
      Those are for Spring and Summer, for Fall and Winter Gua the opposite direction.`
  },
  {
    key: 4,
    title: 'Coughing',
    desc: `Spring and Summer: Gua the Lung meridian in the forearm, from the wrist to the elbow, 50 times; do it when feel like coughing, repeat it again when coughing happened, usually takes 2-3 days to stop coughing completely.
      Fall and Winter: Gua the Lung meridian in the forarm, from the elbow to the wrist, 50 times; do it when feel like coughing, repeat it again when coughing happened, usually takes 2-3 days to stop coughing completely.`
  }, {
    key: 5,
    title: 'Fever',
    desc: `Gua the Bladder meridian in the upper back, from up to down, each line 50 times; repeat it again when the fever up again. It takes 1-2 days to have the fever gone completely.`
  },
  {
    key: 6,
    title: 'Allergy and Cold symptoms',
    desc: 'Exercise methods that can relieve Allergy and Cold symptoms.  Pictured by George Ji.'
  }
];

export default function Album() {

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <HeroContent>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Holistic Healing
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              <p>
                Our mission at Wen's Acupuncture is to provide our patients with an integrated and holistic approach to
                healing. We do not fix problems. Rather, we enrich our patients’ lives by teaching them how to self-heal
                and by helping them unlock their healing potential.</p>

              <p>
                Traditional Chinese medical theory believe sickness came with stages. In the early stage, it's easy to be
                healed thus Wendy invented some simple methods that can raises body's alert on early symptom thus prevent
                sickness proceeding.
              </p>

            </Typography>
          </Container>
        </HeroContent>
        <CardGrid maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.key} xs={12} sm={6} md={4}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <CardMedia
                    sx={{paddingTop: '56.25%'}}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography>
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardGrid>
      </main>
    </React.Fragment>
  );
}

