import * as React from 'react';
import { Box, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Wen's Acupuncture
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface FooterProps {
  description: string,
};

function Footer(props: FooterProps) {
  const { description } = props;

  return (
    <Box component="footer" sx={{
      position: 'fixed', // Use 'absolute' if you want it relative to a positioned parent
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      // Additional styling as needed
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      Height:"50px",
      width:"50vw",
      padding: "10px",
      //marginBottom: (theme: Theme) => theme.spacing(6, 0),
    }}>
      <Container maxWidth="lg">
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          {description}
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;
