import axios from 'axios'
import jwtManager from '../lib/tokenManager'
import {Workhour} from "../types/common"

export function createWorkhour(values:Workhour) {
    let token = jwtManager.getNonce();
    if (token) {
        return axios.post(
            '/bookings',
            values,
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => res.data);
    } else {
        return axios.post(
            '/bookings',
            values)
            .then((res) => res.data);

    }
}

export function deleteWorkhour(bookingId:number) {
    let nonce = jwtManager.getNonce();
    if (nonce) {
        return axios.delete(
            `/admin/bookings/${bookingId}`,
            { headers: { nonce } }
        ).then((res) => res.data);
    } else {
        return Promise.reject("deleteWorkhour Not authorized");
    }
}

export function updateWorkhour(values:Workhour) {
        return axios.patch(`/admin/bookings/${values.id}`,
            values)
            .then((res) => res.data);
}

export function fetchWorkhour(bookingId:number) {
        return axios.get(`/bookings/${bookingId}`).then((res) => res.data);
}

export async function getWeeklyWorkhours(mondayDate: Date) :Promise<Workhour[]> {
    let nextMondayDate = new Date(mondayDate);
    nextMondayDate.setDate(nextMondayDate.getDate() + 7);
    let token = jwtManager.getNonce();
    if (token) {
        let data = await axios.get(
            `/search/workhours?event_date_min=${mondayDate.toDateString()}&event_date_max=${nextMondayDate}`,
            { headers: { Nonce: `${token}` } });
        return data as unknown as Workhour[];
    }
    return [];
}

export async function getLastConfiguredWorkhour() :Promise<Workhour|null> {
    let token = jwtManager.getNonce();
    if (token) {
        let data = await axios.get(
            `/workhours/last`,
            { headers: { Nonce: `${token}` } });
        return data as unknown as Workhour;
    }
    return null;
}
