import React from 'react';
import { Box } from '@mui/material';

export default function map() {
    return (
        <Box sx={{
            width: "100%",
            height: "100%"
        }}>
            <Box sx={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: "absolute"
            }}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2944.168760647748!2d-71.23024938414751!3d42.4454252791812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e39dcf804d6bc5%3A0x432cf9bb18d1eaf9!2s114%20Waltham%20St%2C%20Lexington%2C%20MA%2002421!5e0!3m2!1sen!2sus!4v1639365225089!5m2!1sen!2sus"
                    width="100%" height="100%" allowFullScreen={false} loading="lazy" style={{ border: "0px" }}></iframe>
            </Box>
        </Box>
    );
}

